<template>
  <NuxtLink
    v-if="path"
    :to="path"
    :class="buttonClass"
    :target="isExternal(path) ? '_blank' : ''"
    @click="$emit('click')"
  >
    <slot />
  </NuxtLink>
  <button v-else :class="buttonClass" @click="$emit('click')">
    <slot />
  </button>
</template>
<script lang="ts" setup>
/*
 * @description 公共Button组件只作为一个容器，只有布局相关的style
 * 没有固定的宽高及其他样式，因此还需要在外面加入自定义class来设置style
 * 同时button 可以支持链接形式，因此需要path属性，同时会针对path判断是否为外链
 * 这样做的目的 只是为了统一规范
 */
import { PropType } from 'vue'
type ButtonTypes = 'horizontal' | 'vertical'
type ButtonJustifyTypes = 'start' | 'end' | 'center' | 'space-between' | 'space-around'
const props = defineProps({
  type: {
    type: String as PropType<ButtonTypes>,
    default: 'horizontal'
  },
  justify: {
    type: String as PropType<ButtonJustifyTypes>,
    default: 'center'
  },
  className: null as unknown as PropType<unknown>,
  path: {
    type: String,
    default: ''
  }
})
defineEmits(['click'])
const buttonClass = computed(() => {
  return ['com-button', props.type, props.justify, props.className]
})
const isExternal = (path: string) => {
  return /^(https?:|mailto:|tel:)/.test(path)
}
</script>
<style lang="scss" scoped>
.com-button {
  display: inline-flex;
  align-items: center; // 默认垂直居中
  &.start {
    justify-content: flex-start;
  }
  &.end {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
}
</style>
