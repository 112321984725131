<template>
  <Layout v-if="!accessTipsPopupShow">
    <LazyLayoutHeader class="app-layout-header" position="fixed">
      <CommonContainer class-name="app-layout-header-container">
        <CommonNav>
          <template #logo>
            <CommonLogo path="/" @click="menuOpen = false">
              <template #icon>
                <svg class="app-nav-logo" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_220_4122)">
                    <path
                      d="M12.3975 16.59L16.625 12.36C17.6475 11.3375 19.3025 11.3375 20.3275 12.36L28.045 20.0775C29.0675 21.1 29.0675 22.755 28.045 23.78L23.8175 28.0075C22.795 29.03 21.14 29.03 20.115 28.0075L12.3975 20.29C11.375 19.2675 11.375 17.6125 12.3975 16.59Z"
                      fill="#FABF00"
                    />
                    <path
                      d="M15.0175 31.68L18.4125 31.8925L8.28 21.7575C6.235 19.7125 6.235 16.4 8.28 14.36L14.36 8.28C16.405 6.235 19.7175 6.235 21.7575 8.28L31.8875 18.41L31.675 15.015L34 12.9475L31.8875 7.8375L29 8.0175L25.9825 5L26.165 2.1125L21.0525 0L18.985 2.325H14.8425L12.95 0L8.0175 2.1125L8.11 5.0875L5.085 8.1125L2.11 8.02L0 12.95L2.325 14.845V18.99L0 21.0525L2.1125 26.1625L5 25.9825L8.0175 29L7.835 31.8875L12.9475 34L15.0175 31.68Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_220_4122">
                      <rect width="34" height="34" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </CommonLogo>
          </template>
          <!--  在webstorm中props限制了类型且基于nuxt3自动导入ref导致类型报错, 需手动导入ref -->
          <CommonLang
            v-if="langList.length > 1"
            v-model:open="langOpen"
            :class="['app-menu-lang', langOpen && 'show']"
            :list="langList"
            :mask="false"
            :global="globalLang"
          />
          <CommonDownloadButton
            v-if="downloadInfo?.status"
            class="app-nav-download"
            :link="downloadInfo?.url"
            platform="desktop"
          >
            {{ downloadInfo?.name }}
          </CommonDownloadButton>
          <CommonMenu
            v-model:open="menuOpen"
            v-model:subMenuOpen="subMenuOpen"
            menu-btn-class="app-nav-btn"
            :list="menuList"
          />
        </CommonNav>
      </CommonContainer>
    </LazyLayoutHeader>
    <LayoutMain :class="$route.path.includes('docs') && 'app-docs-main-inner'">
      <slot />
    </LayoutMain>
    <LayoutFooter v-if="footType" :type="footType">
      <CommonContact
        v-if="socialList.length"
        class="layout-footer-contact"
        :title="formatTransify('UGC_OFFICIAL_WEBSITE_FOOTER_FOLLOW')"
        :list="socialList"
      />
      <CommonLogo size="large" :is-link="false">
        <template #icon>
          <img class="app-footer-logo" src="@/assets/images/logo-garena.png" alt="" />
        </template>
      </CommonLogo>
      <template #info>
        <!--        <CommonLang-->
        <!--          v-if="langList.length > 1 && footLanguage"-->
        <!--          v-model:open="langBotOpen"-->
        <!--          class-name="layout-footer-lang"-->
        <!--          type="text"-->
        <!--          direction="top"-->
        <!--          :global="globalLang"-->
        <!--          :list="langList"-->
        <!--        />-->
        <CommonLinks v-if="privacyList.length" :list="privacyList" />
        <CommonCopyRight v-if="copyRight" :content="copyRight" />
        <CommonLimits v-if="limits.length" :list="limits" />
      </template>
    </LayoutFooter>
    <CommonDownloadPopup
      v-model:show="downloadPopupShow"
      :title="downloadInfo?.popup.title"
      :desc="downloadInfo?.popup.desc"
      :url="downloadInfo?.popup.url"
    />
  </Layout>
  <Layout v-else>
    <client-only>
      <div class="app-access-tip-container">
        <CommonAccessTipPopup
          v-model:show="accessTipsPopupShow"
          :title="accessTipsInfo?.popup.title"
          :desc="accessTipsInfo?.popup.desc"
          :url="accessTipsInfo?.popup.url"
        />
      </div>
    </client-only>
  </Layout>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'

const langOpen = ref(false)
// const langBotOpen = ref(false)
const menuOpen = ref(false)
const subMenuOpen = ref(false)
const { formatTransify } = useStore()
// config data
const { config, downloadInfo, downloadPopupShow, accessTipsInfo, accessTipsPopupShow } = storeToRefs(useStore())
// nav data
const footType = computed(() => config.value.footer.type)
// const footLanguage = computed(() => config.value.footer.language)
const langList = computed(() => config.value.lang_list)
const globalLang = computed(() => config.value.lang_list.some((item) => item.region === 'GLOBAL'))
const menuList = computed(() => config.value.menu)
// footer data
const socialList = computed(() => config.value.footer.social_media)
const privacyList = computed(() => config.value.footer.privacy_policy)
const copyRight = computed(() => config.value.footer.copyright)
const limits = computed(() => config.value.footer.age_limit)

watch(
  () => langOpen.value,
  (value) => {
    value && (menuOpen.value = false)
  }
)
watch(
  () => menuOpen.value,
  (value) => {
    value && (langOpen.value = false)
  }
)
</script>
<style lang="scss">
.app-access-tip-container {
  background-image: url('@/assets/images/access-tip-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.app-nav-btn {
  margin-left: 30px;
}
.app-docs-main-inner {
  display: flex;
  justify-content: center;
}
@media not all and (min-width: 1024px) {
  .app-access-tip-container {
    background-image: url('@/assets/images/access-tip-mobile-bg.jpg');
  }
  .layout-footer-lang {
    display: none;
  }
  .app-layout-header-container {
    padding: 0 30px 0 40px;
  }
  .app-nav-logo {
    width: 52px;
    height: 52px;
  }
  .app-footer-logo {
    width: 250px;
    height: 64px;
  }
  //.app-menu-lang {
  //  display: none !important;
  //  &.show {
  //    display: flex !important;
  //  }
  //}
}
@media screen and (min-width: 1024px) {
  .layout-footer-contact {
    max-width: 25%;
    order: 2;
    margin-top: 1rem;
  }
  .app-nav-logo {
    width: 34px;
    height: 34px;
  }
  .app-nav-download {
    margin-right: 22px;
  }
  .app-footer-logo {
    width: 250px;
    height: 64px;
  }
}
</style>
