<template>
  <section :class="layoutClass">
    <slot />
  </section>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
const props = defineProps({
  direction: {
    type: String as PropType<Direction>,
    default: 'column'
  }
})
const layoutClass = computed(() => {
  return ['com-layout', `com-layout-${props.direction}`]
})
</script>
<style lang="scss" scoped>
.com-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.com-layout-row {
  flex-direction: row;
}
.com-layout-column {
  flex-direction: column;
}
.com-layout-row-reverse {
  flex-direction: row-reverse;
}
.com-layout-column-reverse {
  flex-direction: column-reverse;
}
</style>
