import { defineComponent } from 'vue'
import './index.scss'
import { prefixClass } from '~/components/gfr/utils'
export default defineComponent({
  name: 'GfrScrollBar',
  props: {
    native: Boolean,
    height: [String, Number],
    always: Boolean,
    trackStyle: Object,
    thumbStyle: Object,
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props, { slots }) {
    const wrapper = ref<HTMLElement>()
    const scrollbar = ref<HTMLElement>()
    const baseClass = prefixClass('scrollbar')
    const scrollLength = ref(0)
    const scrollSize = ref(0)
    const wrapperSize = ref(0)
    const thumbHeight = computed(() => {
      return (wrapperSize.value / scrollSize.value) * wrapperSize.value
    })
    const thumbSizeStyle = computed(() => {
      return props.direction === 'vertical'
        ? { height: thumbHeight.value + 'px', transform: `translateY(${scrollLength.value}px)` }
        : { width: thumbHeight.value + 'px', transform: `translateX(${scrollLength.value}px)` }
    })
    onMounted(async () => {
      await nextTick()
      if (wrapper.value && !scrollSize.value && !wrapperSize.value) {
        scrollSize.value = wrapper.value[props.direction === 'vertical' ? 'scrollHeight' : 'scrollWidth']
        wrapperSize.value = wrapper.value[props.direction === 'vertical' ? 'clientHeight' : 'clientWidth']
      }
      wrapper.value?.addEventListener('scroll', () => {
        if (wrapper.value && !scrollSize.value && !wrapperSize.value) {
          scrollSize.value = wrapper.value[props.direction === 'vertical' ? 'scrollHeight' : 'scrollWidth']
          wrapperSize.value = wrapper.value[props.direction === 'vertical' ? 'clientHeight' : 'clientWidth']
        }
        wrapper.value &&
          (scrollLength.value =
            (wrapper.value[props.direction === 'vertical' ? 'scrollTop' : 'scrollLeft'] / scrollSize.value) *
            wrapperSize.value)
      })
    })
    onUpdated(async () => {
      await nextTick()
      if (wrapper.value && !scrollSize.value && !wrapperSize.value) {
        scrollSize.value = wrapper.value[props.direction === 'vertical' ? 'scrollHeight' : 'scrollWidth']
        wrapperSize.value = wrapper.value[props.direction === 'vertical' ? 'clientHeight' : 'clientWidth']
      }
    })
    return () => (
      <div ref={scrollbar} class={baseClass}>
        <div
          ref={wrapper}
          class={[
            `${baseClass}-wrapper`,
            `${baseClass}-wrapper-${props.direction}`,
            props.native && `${baseClass}-native`
          ]}
        >
          {slots.default?.()}
        </div>
        {!props.native && scrollSize.value > wrapperSize.value && (
          <div
            class={[`${baseClass}-track`, props.always && `${baseClass}-always`, props.direction]}
            style={props.trackStyle}
          >
            <div class={`${baseClass}-thumb`} style={{ ...props.thumbStyle, ...thumbSizeStyle.value }}></div>
          </div>
        )}
      </div>
    )
  }
})
