import { defineComponent, PropType } from 'vue'
import GfrPopup, { PopupOverProps } from '../Popup'
import { prefixClass } from '~/components/gfr/utils'
import { pick, UnknownProp } from '~/components/gfr/utils/base'
import './index.scss'
type CloseIconPlacement = 'top-out-left' | 'top-out-right' | 'top-in-left' | 'top-in-right'
const popupKeys = [...Object.keys(PopupOverProps), 'transition', 'closeable', 'closeOnPopstate']
interface CloseIconProps {
  placement: CloseIconPlacement
  class: string
}
const defaultCloseIconProps: CloseIconProps = {
  placement: 'top-in-right',
  class: prefixClass('dialog-close')
}
export default defineComponent({
  name: 'GfrDialog',
  props: {
    ...PopupOverProps,
    className: UnknownProp,
    title: String,
    closeIcon: {
      type: [Boolean, Object] as PropType<CloseIconProps | boolean>,
      default: defaultCloseIconProps
    },
    cancelText: String,
    confirmText: String
  },
  emits: ['update:show', 'click', 'confirm', 'cancel', 'open', 'opened', 'closed', 'close', 'click-overlay'],
  setup(props, { slots, emit }) {
    const baseClass = prefixClass('dialog')
    const closeIconProps = computed(() => {
      if (typeof props.closeIcon === 'boolean' && props.closeIcon) {
        return {
          ...defaultCloseIconProps
        }
      }
      return {
        ...defaultCloseIconProps,
        ...props.closeIcon
      }
    })
    const onUpdateShow = (value: boolean) => {
      emit('update:show', value)
    }
    const onConfirm = () => {
      emit('confirm')
    }
    const onCancel = () => {
      emit('cancel')
      close()
    }
    const close = () => {
      if (props.closeable) {
        onUpdateShow(false)
      }
    }
    const onClosed = () => {
      emit('closed')
    }
    const buttonGroupRender = () => {
      return (
        (props.cancelText || props.confirmText) && (
          <div class={`${baseClass}-button-group`}>
            {props.cancelText && (
              <button class={prefixClass('dialog-button-cancel')} onClick={onCancel}>
                <span class={prefixClass('dialog-button-text')}>{props.cancelText}</span>
              </button>
            )}
            {props.confirmText && (
              <button class={prefixClass('dialog-button-confirm')} onClick={onConfirm}>
                <span class={prefixClass('dialog-button-text')}>{props.confirmText}</span>
              </button>
            )}
          </div>
        )
      )
    }
    const closeIcon = () => {
      const { closeIcon, closeable } = props
      if (closeIcon && closeable) {
        return (
          <button class={[`${baseClass}-close`, `${baseClass}-close-${closeIconProps.value.placement}`]}>
            <svg
              class={`${baseClass}-close-icon`}
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={close}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.9394 7L0.469727 1.53033L1.53039 0.469666L7.00006 5.93934L12.4697 0.469666L13.5304 1.53033L8.06072 7L13.5304 12.4697L12.4697 13.5303L7.00006 8.06066L1.53039 13.5303L0.469727 12.4697L5.9394 7Z"
                fill="#DCE0E7"
              />
            </svg>
          </button>
        )
      }
    }
    const titleRender = () => {
      const title = slots.title ? slots.title() : props.title
      if (title) {
        return <div class={`${baseClass}-title`}>{title}</div>
      }
    }
    const dialogRender = () => {
      return <div class={[baseClass + '-inner']}>{slots.content && slots.content()}</div>
    }
    return () => (
      <GfrPopup
        {...{
          ...pick(props, popupKeys),
          'onUpdate:show': onUpdateShow
        }}
        onClosed={onClosed}
      >
        <div class={[baseClass, props.className]}>
          {closeIcon()}
          {titleRender()}
          {dialogRender()}
          {buttonGroupRender()}
        </div>
      </GfrPopup>
    )
  }
})
