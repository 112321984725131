<template>
  <GfrDialog
    :show="show"
    class-name="com-access-tip-dialog"
    transition="slide-down"
    placement="top"
    :confirm-text="formatTransify('UGC_OFFICIAL_WEBSITE_DOWNLOAD_CONFIRM')"
    :closeable="false"
    @update:show="onUpdateShow"
    @confirm="onConfirm"
  >
    <template #title>
      <GfrTitle class="com-access-tip-dialog-title" :level="2" placement="center">
        <span class="com-access-tip-dialog-text">{{ title }}</span>
      </GfrTitle>
    </template>
    <template #content>
      <div class="com-access-tip-dialog-ctx">
        <GfrScrollBar class="com-access-tip-dialog-scroll">
          <div class="com-access-tip-dialog-content" v-html="desc"></div>
        </GfrScrollBar>
      </div>
    </template>
  </GfrDialog>
</template>
<script lang="ts" setup>
const props = defineProps({
  show: Boolean,
  title: String,
  desc: String,
  url: String
})
const emit = defineEmits(['update:show'])
const { formatTransify } = useStore()
const onUpdateShow = (value: boolean) => {
  emit('update:show', value)
}
const onConfirm = () => {
  // todo redirect link
  window.location.href = props.url as string
}
</script>
<style lang="scss">
.com-access-tip-dialog {
  width: 692px;
  height: 302px;
  .gfr-dialog-button-group {
    display: flex;
    justify-content: center;
  }
  .gfr-dialog-button-confirm {
    width: 188px;
    height: 46px;
    font-size: 16px;
    color: #141414;
    text-transform: uppercase;
    margin-top: 10px;
    //&:hover {
    //  background-color: #fff500;
    //}
  }
}
@media not all and (min-width: 1024px) {
  .com-access-tip-dialog {
    width: 670px;
    height: 436px;
    .gfr-dialog-button-confirm {
      width: 260px;
      height: 60px;
      font-size: 28px;
      margin-top: 20px;
      //&:hover {
      //  background-color: var(--theme-primary);
      //}
    }
  }
}
</style>
<style lang="scss" scoped>
.com-access-tip-dialog-title {
  padding: 32px 24px 0;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.com-access-tip-dialog-text {
  margin-left: 8px;
  font-weight: 700;
  font-size: 20px;
}
.com-access-tip-dialog-logo {
  width: 20px;
  height: 20px;
}
.com-access-tip-dialog-ctx {
  height: 152px;
  padding: 32px 30px;
  overflow-y: auto;
}
.com-access-tip-dialog-scroll {
  height: 100%;
  padding-right: 16px;
}
.com-access-tip-dialog-content {
  &:deep(*) {
    color: #c8ccd3;
    line-height: normal;
  }
  &:deep(p) {
    font-size: 16px;
    line-height: 22px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4d5158;
    border-radius: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
@media not all and (min-width: 1024px) {
  .com-access-tip-dialog-title {
    padding: 44px 0 0;
  }
  .com-access-tip-dialog-text {
    font-size: 32px;
  }
  .com-access-tip-dialog-ctx {
    height: 208px;
    padding: 20px 44px;
  }
  .com-access-tip-dialog-scroll {
    padding-right: 16px;
  }
  .com-access-tip-dialog-content {
    &:deep(p) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
</style>
