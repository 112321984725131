import { PropType } from 'vue'

export function noFunc() {}

export const UnknownProp = null as unknown as PropType<unknown>
export const inBrowser = typeof window !== 'undefined'

export function isDef<T>(val: T): val is NonNullable<T> {
  return val !== undefined && val !== null
}

export function isObject<T>(val: T): val is Record<any, any> {
  return val !== null && typeof val === 'object'
}

export function pick(obj: Record<string, any>, keys: string[]) {
  return keys.reduce((ret, key) => {
    ret[key] = obj[key]
    return ret
  }, {} as Record<string, any>)
}
