<template>
  <nav :class="navClass">
    <slot name="logo"></slot>
    <div class="com-nav-content">
      <slot />
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
const props = defineProps({
  className: null as unknown as PropType<unknown>,
  responsive: {
    type: Boolean,
    default: true
  }
})
const navClass = computed(() => {
  const navBaseClass = 'com-nav'
  return [navBaseClass, props.responsive && `${navBaseClass}-responsive`, props.className]
})
</script>
<style lang="scss" scoped>
.com-nav {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.com-nav-logo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.com-nav-content {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
}
@media not all and (min-width: 1024px) {
  .com-nav-responsive {
    .com-nav-content {
      justify-content: flex-end;
    }
  }
}
@media screen and (min-width: 1024px) {
  .com-nav-responsive {
    .com-nav-content {
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }
}
</style>
