<template>
  <GfrDialog
    :show="show"
    class-name="com-download-dialog"
    transition="slide-down"
    placement="top"
    :confirm-text="formatTransify('UGC_OFFICIAL_WEBSITE_DOWNLOAD_CONFIRM')"
    :cancel-text="formatTransify('UGC_OFFICIAL_WEBSITE_DOWNLOAD_CANCEL')"
    @update:show="onUpdateShow"
    @cancel="onCancel"
    @confirm="onConfirm"
  >
    <template #title>
      <GfrTitle class="com-download-dialog-title" :level="4" placement="center">
        <span class="com-download-dialog-text">{{ title }}</span>
      </GfrTitle>
    </template>
    <template #content>
      <div class="com-download-dialog-ctx">
        <GfrScrollBar class="com-download-dialog-scroll">
          <div class="com-download-dialog-content" v-html="desc"></div>
        </GfrScrollBar>
      </div>
    </template>
  </GfrDialog>
</template>
<script lang="ts" setup>
import { downloadFile } from '~/utils'
const gtag = useGtag()
const props = defineProps({
  show: Boolean,
  title: String,
  desc: String,
  url: String
})
const emit = defineEmits(['update:show'])
const { formatTransify } = useStore()
const onUpdateShow = (value: boolean) => {
  emit('update:show', value)
}
const onConfirm = () => {
  gtag('event', 'download_popup_confirm', { event_category: 'Download', event_label: true })
  props.url && downloadFile(props.url)
  onUpdateShow(false)
  gtag('event', 'download_client', { event_category: 'Download', event_label: true })
}
const onCancel = () => {
  gtag('event', 'download_popup_cancel', { event_category: 'Download', event_label: true })
}
</script>
<style lang="scss">
.com-download-dialog {
  width: 692px;
  height: 302px;
  .gfr-dialog-button-group {
    display: flex;
    justify-content: center;
  }
  .gfr-dialog-button-cancel,
  .gfr-dialog-button-confirm {
    width: 188px;
    height: 46px;
    font-size: 16px;
    color: #141414;
    text-transform: uppercase;
    margin: 10px 20px;
  }
  .gfr-dialog-button-cancel {
    color: #ffffff;
    //&:hover {
    //  background-color: #666666;
    //}
  }
  //.gfr-dialog-button-confirm:hover {
  //  background-color: #fff500;
  //}
}
</style>
<style lang="scss" scoped>
.com-download-dialog-title {
  padding: 32px 0 0;
  text-transform: uppercase;
}
.com-download-dialog-text {
  margin-left: 8px;
  font-weight: 700;
}
.com-download-dialog-logo {
  width: 20px;
  height: 20px;
}
.com-download-dialog-ctx {
  height: 152px;
  padding: 32px 30px;
  overflow-y: auto;
}
.com-download-dialog-scroll {
  height: 100%;
  padding-right: 16px;
}
.com-download-dialog-content {
  &:deep(*) {
    color: #c8ccd3;
    line-height: normal;
  }
  &:deep(i) {
    font-style: italic;
  }
  &:deep(p) {
    font-size: 16px;
    line-height: 22px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4d5158;
    border-radius: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
</style>
