import { defineComponent, PropType, computed, Transition } from 'vue'
import { TransitionType } from '~/components/gfr/utils/types'
import { prefixAnime, prefixClass } from '~/components/gfr/utils'
import { noFunc, UnknownProp } from '~/components/gfr/utils/base'
import './index.scss'
export const OverlayOverProps = {
  show: Boolean,
  lockScroll: Boolean,
  zIndex: [Number, String],
  transition: {
    type: String as PropType<TransitionType>,
    default: 'fade'
  }
}
export default defineComponent({
  name: 'GfrOverlay',
  props: {
    ...OverlayOverProps,
    className: UnknownProp,
    backgroundColor: {
      type: String,
      default: 'rgba(0, 0, 0, .5)'
    }
  },
  emits: ['click', 'close', 'onClosed'],
  setup(props, { slots, emit }) {
    const baseClass = prefixClass('overlay')
    const styles = computed(() => {
      return {
        zIndex: props.zIndex,
        backgroundColor: props.backgroundColor
      }
    })
    const overlayClick = () => {
      emit('click')
    }
    const onClosed = () => {
      emit('onClosed')
    }
    const lockScroll = (show: boolean) => {
      if (show) {
        props.lockScroll && document.body.classList.add('ban-scroll')
      } else {
        props.lockScroll && document.body.classList.remove('ban-scroll')
      }
    }
    const preventTouchMove = (event: TouchEvent) => {
      preventDefault(event, true)
    }
    const overlayRender = () => (
      <Transition name={prefixAnime(props.transition)} onAfterLeave={onClosed}>
        <div
          v-show={props.show}
          style={{ ...styles.value }}
          class={[baseClass, props.className]}
          onClick={overlayClick}
          onTouchmove={props.lockScroll ? preventTouchMove : noFunc}
        >
          {slots.default?.()}
        </div>
      </Transition>
    )
    watch(
      () => props.show,
      (value) => {
        lockScroll(value)
      }
    )
    onMounted(() => {
      lockScroll(props.show)
    })
    return () => overlayRender()
  }
})
