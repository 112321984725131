<template>
  <NuxtLink v-if="isLink" :class="['com-logo']" :to="pathWithParams" @click="$emit('click')">
    <slot name="icon"></slot>
  </NuxtLink>
  <div v-else :class="['com-logo']" @click="$emit('click')">
    <slot name="icon"></slot>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
type SizeType = 'small' | 'large'
const route = useRoute()
const props = defineProps({
  isLink: {
    type: Boolean,
    default: true
  },
  path: {
    type: String,
    default: '/'
  },
  size: {
    type: String as PropType<SizeType>,
    default: 'small'
  },
  name: {
    type: String,
    default: ''
  }
})
defineEmits(['click'])
const pathWithParams = computed(() => {
  return route.params.lang && props.path === '/' ? `/${route.params.lang}/` : props.path
})
</script>
<style lang="scss" scoped>
.com-logo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
