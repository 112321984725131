import { CSSProperties, defineComponent, PropType, Teleport, TeleportProps, Transition } from 'vue'
import GfrOverlay, { OverlayOverProps } from '../Overlay'
import { prefixAnime, prefixClass } from '~/components/gfr/utils'
import { TransitionType } from '~/components/gfr/utils/types'
import { UnknownProp } from '~/components/gfr/utils/base'
import './index.scss'
import { stopPropagation } from '~/components/gfr/utils/event'
let globalZIndex = 999
interface overlayConfig {
  backgroundColor?: string
  closeOnClick?: boolean
  class?: string
  transition?: TransitionType
  duration?: number
}
export type PopupPlacement = 'top' | 'bottom' | 'left' | 'right' | 'center'
export const defaultOverlayProps: overlayConfig = {
  backgroundColor: 'rgba(0, 0, 0, .5)',
  closeOnClick: true,
  class: prefixClass('popup-overlay'),
  transition: 'fade',
  duration: 300
}
export const PopupOverProps = {
  ...OverlayOverProps,
  overlay: {
    type: [Boolean, Object] as PropType<overlayConfig | boolean>,
    default: defaultOverlayProps
  },
  closeOnPopstate: {
    type: Boolean,
    default: true
  },
  closeable: {
    type: Boolean,
    default: true
  },
  teleport: {
    type: [String, Object] as PropType<TeleportProps['to']>,
    default: '#teleports'
  },
  placement: {
    type: String as PropType<PopupPlacement>,
    default: 'center'
  }
}

export default defineComponent({
  name: 'GfrPopup',
  props: {
    ...PopupOverProps,
    className: UnknownProp
  },
  emits: ['update:show', 'click', 'open', 'opened', 'closed', 'close', 'click-overlay'],
  setup(props, { slots, attrs, emit }) {
    let opened = false
    let needOpen = false
    const popupShow = ref(false)
    const popupRef = ref<HTMLElement>()
    const baseClass = prefixClass('popup')
    const zIndex = ref<number>()
    const overlayProps = computed(() => {
      if (typeof props.overlay === 'boolean' && props.overlay) {
        return {
          ...defaultOverlayProps
        }
      }
      return {
        ...defaultOverlayProps,
        ...props.overlay
      }
    })
    const onClickOverlay = () => {
      emit('click-overlay')
      if (overlayProps.value.closeOnClick && props.closeable) {
        close()
      }
    }
    const open = () => {
      if (!opened) {
        if (props.zIndex !== undefined) {
          globalZIndex = +props.zIndex
        }
        opened = true
        zIndex.value = ++globalZIndex
        globalZIndex = 999
        popupShow.value = true
      }
    }
    const close = () => {
      if (opened) {
        opened = false
        emit('update:show', false)
      }
    }
    const onOpened = () => {
      emit('opened')
    }
    const onClosed = () => {
      popupShow.value = false
      emit('closed')
    }
    const onClick = () => {
      emit('click')
    }
    const style = computed(() => {
      const style: CSSProperties = {
        zIndex: zIndex.value
      }
      return style
    })
    const popupRender = () => (
      <GfrOverlay
        className={overlayProps.value.class}
        show={popupShow.value}
        zIndex={zIndex.value}
        backgroundColor={overlayProps.value.backgroundColor}
        onClick={onClickOverlay}
        lockScroll={props.lockScroll}
        transition={overlayProps.value.transition}
      >
        <div
          ref={popupRef}
          style={style.value}
          class={[baseClass, prefixClass(`popup-${props.placement}`), props.className]}
          onClick={onClick}
          {...attrs}
        >
          {/* {overlayRender()} */}
          <Transition name={prefixAnime(props.transition)} onAfterEnter={onOpened} onAfterLeave={onClosed}>
            <div
              v-show={props.show}
              class={`${baseClass}-inner`}
              onClick={(event) => {
                stopPropagation(event)
              }}
            >
              {slots.default?.()}
            </div>
          </Transition>
        </div>
      </GfrOverlay>
    )

    watch(
      () => props.show,
      (value) => {
        if (value) {
          open()
          emit('open')
        } else {
          close()
          emit('close')
        }
      }
    )
    onMounted(() => {
      if (props.show) {
        open()
      }
      window.addEventListener('popstate', () => {
        if (props.closeOnPopstate) {
          close()
          needOpen = false
        }
      })
    })
    onActivated(() => {
      if (needOpen) {
        emit('update:show', true)
        needOpen = false
      }
    })
    onDeactivated(() => {
      if (props.show) {
        close()
        needOpen = true
      }
    })
    return () => {
      if (props.teleport) {
        return <Teleport to={props.teleport}>{popupRender()}</Teleport>
      }
      return <>{popupRender()}</>
    }
  }
})
